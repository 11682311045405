@media print {
  .ordersToPrint {
    height: auto !important;
    overflow: visible !important;
    font-size: 10px;
    background-color: #fff;
    color: #1a202c;
  }

  .ordersToPrint h2 {
    display: block;
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 9px;
    padding-left: 18px;
  }

  .ordersToPrint input {
    border: 0px;
  }

  .ordersToPrint input + div,
  .ordersToPrint .chakra-button__group {
    display: none;
  }
}
